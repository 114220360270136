<template>
  <div>
    <Navbar />
    <b-container class="text-center">
      <RowCol>
        <SectionHeader name="Sifarişlər" />
      </RowCol>
      <RowCol>
        <b-table
          class="shadow"
          bordered
          hover
          small
          :items="items"
          :fields="fields"
        >
          <template #cell(pub_date)="data">
            {{ data.item.pub_date | format_date }}
          </template>
          <template #cell(qeyd)="data">
            <div style="font-size: 14px">{{ data.item.qeyd }}</div>
          </template>
          <template #cell(-)="data">
            <b-button
              variant="light"
              class="border shadow-sm font-weight-bold text-primary"
              v-b-modal.details_modal
              @click="showInvoiceDetails(data.item.id)"
            >
              Göstər
            </b-button>
          </template>
        </b-table>
      </RowCol>
      <b-modal id="details_modal" hide-header centered size="xl" hide-footer>
        <div v-if="disabled" class="d-flex flex-column align-items-center">
          <b-spinner />
        </div>
        <b-table
          class="text-center"
          v-if="!disabled"
          bordered
          hover
          small
          :items="detail_items"
          :fields="detail_fields"
        >
          <template #cell(N)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(description)="data">
            {{
              data.item.description && data.item.description.length > 0
                ? data.item.description.substring(0, 30)
                : null
            }}
          </template>
          <template #cell(price)="data">
            {{ parseFloat(data.item.price).toFixed(2) }}
          </template>
          <template #cell(mebleg)="data">
            {{ parseFloat(data.item.quantity * data.item.price).toFixed(2) }}
          </template>
        </b-table>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
import RowCol from "@/components/RowCol";
import SectionHeader from "@/components/SectionHeader";

export default {
  name: "BasketOrders",
  components: { SectionHeader, RowCol, Navbar },
  data() {
    return {
      currentPage: 1,
      items: [],
      detail_items: [],
      fields: [
        { key: "id", label: "Sifariş N:" },
        { key: "pub_date", label: "Tarix" },
        "qeyd",
        {
          key: "amount",
          label: "Cəm Məbləğ (AZN)",
        },
        "-",
      ],
      detail_fields: [
        "N",
        { key: "brand", label: "Brend" },
        { key: "description", label: "Malın Adı" },
        { key: "kod", label: "Kod" },
        { key: "quantity", label: "Say" },
        { key: "price", label: "Qiymət (AZN) " },
        { key: "mebleg", label: "Məbləğ (AZN)" },
      ],
      total: null,
      disabled: false,
    };
  },
  created() {
    this.fetchResults();
    this.$store.commit("SET_SECTION", "SİFARİŞLƏR");
  },
  methods: {
    fetchResults() {
      this.disabled = true;
      const data = { page: this.currentPage };
      axios.post("/basket/old/orders", data).then((data) => {
        this.disabled = false;
        this.items = data.data.results;
      });
    },
    showInvoiceDetails(id) {
      const data = { invoice_id: id };
      this.disabled = true;
      axios.post("/basket/modal", data).then((result) => {
        this.disabled = false;
        this.detail_items = result.data.results;
      });
    },
  },
};
</script>
